import { DeepKeys } from '@tanstack/react-table';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { SearchMeta, WithSearchableActions } from 'app/hooks/search/types';
import { useEffectOnce } from 'app/hooks/useEffectOnce';
import has from 'lodash-es/has';
import cloneDeep from 'lodash-es/cloneDeep';
import set from 'lodash-es/set';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Pattern, match } from 'ts-pattern';
import { StoreApi, UseBoundStore } from 'zustand';

export function transferSearchParamsToFormik<Values extends Record<string, unknown>>({
  searchParams,
  values,
  parse = val => JSON.parse(val)
}: {
  searchParams: URLSearchParams;
  values: Values;
  parse?: (value: string, queryParam?: string) => unknown;
}) {
  const newSearchParams = new URLSearchParams(searchParams.toString());

  const newValues: { [k in DeepKeys<Values>]?: unknown } = {};

  return Array.from(newSearchParams.entries()).reduce(
    (acc, [field, value]) => {
      if (has(values, field)) {
        const path = field as DeepKeys<Values>;
        let decoded: unknown = decodeURIComponent(value);
        try {
          decoded = parse(String(decoded), field);
        } catch {}

        acc.values[path] = decoded;
        acc.searchParams.delete(field);
        acc.changed = true;
      }

      return acc;
    },
    { changed: false, searchParams: newSearchParams, values: newValues }
  );
}

export function useHydrateSearchForm<
  Values extends { meta: SearchMeta },
  Store extends UseBoundStore<StoreApi<WithSearchableActions<Values>>>
>({
  store,
  getFormState,
  parse
}: {
  store: Store;
  getFormState: (state: WithSearchableActions<Values>) => Values;
  parse?: (value: string, queryParam?: string) => unknown;
}) {
  const [status, setStatus] = useState<'initializing' | 'ready'>('initializing');

  const [initialValues, setInitialValues] = useState(() => getFormState(store.getState()));

  const [searchParams, setSearchParams] = useSearchParams();
  const [urlSavedSearchId] = useState(searchParams.get('savedSearchId'));
  const defaultSearchId = useCurrentUserAttribute('defaultSearchId');

  useEffectOnce(() => {
    const transferResult = transferSearchParamsToFormik({ searchParams, values: initialValues, parse });

    if (transferResult.changed) {
      setInitialValues(current => {
        const draft = cloneDeep(current);
        Object.entries(transferResult.values).forEach(([field, value]) => {
          set(draft, field as DeepKeys<Values>, value);
        });
        return draft;
      });
    }

    setSearchParams(transferResult.searchParams);
    setStatus('ready');
  });

  const { searchState, savedSearchId } = initialValues.meta;

  const meta: typeof initialValues.meta = {
    ...initialValues.meta,
    savedSearchId: match({ urlSavedSearchId, defaultSearchId, searchState })
      .with({ urlSavedSearchId: Pattern.not(Pattern.nullish) }, () => urlSavedSearchId)
      .with(
        {
          defaultSearchId: Pattern.not(Pattern.nullish),
          urlSavedSearchId: Pattern.nullish,
          searchState: 'initializing'
        },
        () => defaultSearchId
      )
      .otherwise(() => savedSearchId)
  };

  return {
    isInitializing: status === 'initializing',
    initialValues: { ...initialValues, meta }
  };
}
