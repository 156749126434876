import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash-es/startCase';
import camelCase from 'lodash-es/camelCase';
import isEmpty from 'lodash-es/isEmpty';
import truncate from 'lodash-es/truncate';

import { DescriptionListRow } from 'app/molecules/DescriptionList/DescriptionList';
import { useGetOppQuery } from 'api/oppsApi';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';

const propTypes = {
  id: PropTypes.string.isRequired
};

export const OppDetailsOrganizationOppContext = ({ id }) => {
  const { data: opp } = useGetOppQuery({ id });

  const context = opp?.organizationOpp?.context;

  if (isEmpty(context)) {
    return null;
  }

  const items = Object.keys(context).map(key => {
    let value = context[key];
    if (isEmpty(value)) {
      return null;
    }

    let label = key;

    if (camelCase(key) === key) {
      label = startCase(key);
    }

    if (key === 'noBidMatchedLogic') {
      label = 'No Bid Matched Logic';
      value = value.join(', ');
    }

    if (label === 'Acknowledged Iteration') {
      label = 'Acknowledged?';
      value = value ? 'Yes' : 'No';
    }

    if (label === 'No Bid Iteration') {
      label = 'No Bid?';
      value = value ? 'Yes' : 'No';
    }

    if (value.startsWith('http')) {
      return (
        <DescriptionListRow
          key={key}
          label={label}
          value={
            <LinkTag target="_blank" rel="noreferrer" className="mt-1 text-sm" tag="a" href={value}>
              {truncate(value.replace(/^https?:\/\//, ''), { length: 45 })}
            </LinkTag>
          }
        />
      );
    }

    return <DescriptionListRow key={key} label={label} value={value} />;
  });

  return items;
};

OppDetailsOrganizationOppContext.propTypes = propTypes;
