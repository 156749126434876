import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { Icon, Tag, Tooltip } from '@blueprintjs/core';

import { OppWorkspaceWorkflowStage } from 'app/organisms/OppWorkspaceWorkflowStage/OppWorkspaceWorkflowStage';
import { OppWorkspaceName } from 'app/organisms/OppWorkspaceName/OppWorkspaceName';
import { LabelsDescriptionListField } from 'app/organisms/LabelsDescriptionListField/LabelsDescriptionListField';
import { DescriptionListField } from 'app/molecules/DescriptionListField/DescriptionListField';
import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';
import { OppDetailsOrganizationOppContext } from 'app/organisms/OppDetailsOrganizationOppContext/OppDetailsOrganizationOppContext';
import { DescriptionListLabel } from 'app/molecules/DescriptionList/DescriptionList';
import { WorkspaceFollowers } from 'app/organisms/WorkspaceFollowers/WorkspaceFollowers';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { DRAWER_PARAMS } from 'app/organisms/AppDrawer/constants';
import { CardList, CardListItem } from 'app/molecules/CardList/CardList';
import { OppWorkspaceAttachmentsCard } from 'app/organisms/OppWorkspaceAttachmentsCard/OppWorkspaceAttachmentsCard';
import { useFeatureFlag } from 'app/hooks/useFeatureFlag';
import { CardHeadingSmall } from 'app/atoms/Typography/Typography';
import { WorkspaceShow } from 'types/__generated__/GovlyApi';

const propTypes = {
  workspace: PropTypes.object.isRequired
};

export const OppWorkspaceCard = ({ workspace }: { workspace: WorkspaceShow }) => {
  const [searchParams] = useSearchParams();
  const drawerType = searchParams.get(DRAWER_PARAMS.type);
  const workspaceImprovementsEnabled = useFeatureFlag('workspace-improvements');

  const {
    createdAt,
    createdByEmail,
    createdByOrganization,
    workableId,
    privateAccess,
    follows,
    organizationLabels,
    workableDisplayName
  } = workspace;

  const participatingOrganizations = [
    ...new Set(
      follows?.map(({ organizationUser }) => organizationUser?.organizationName).filter(name => name !== undefined)
    )
  ].sort(n => (n === createdByOrganization ? -1 : 1));

  return (
    <CardList>
      <CardListItem title="Workspace Details" collapsible defaultIsOpen titleRenderer={CardHeadingSmall}>
        <dl className="grid grid-cols-2 gap-y-4">
          <div className="flex flex-col gap-y-1 col-span-2">
            <DescriptionListLabel className="mb-2">Workspace Name</DescriptionListLabel>
            <div>
              <OppWorkspaceName oppId={workspace.workableId} workspace={workspace} />
            </div>
          </div>
          {workableDisplayName && (
            <DescriptionListField
              label="Opportunity"
              value={
                <Tooltip content="Click to view opportunity in new tab">
                  <LinkTag to={`/opportunities/${workspace.workableId}`} target="_blank">
                    <Tag interactive>{workableDisplayName}</Tag>
                  </LinkTag>
                </Tooltip>
              }
              cols={2}
            />
          )}
          <div className="col-span-2 flex flex-col gap-y-1">
            <DescriptionListLabel className="mb-2">Participants</DescriptionListLabel>
            <div>
              <WorkspaceFollowers
                formTitle={workspace.organizationDefault ? 'Default Workspace' : workspace.name}
                // @ts-expect-error untyped component
                workspace={workspace}
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-1 col-span-2">
            <DescriptionListLabel className="mb-2">Stage</DescriptionListLabel>
            <div>
              <OppWorkspaceWorkflowStage workspaceId={workspace.id} workflowStage={workspace.workflowStage} asTag />
            </div>
          </div>
          <LabelsDescriptionListField
            taggableId={workspace.id}
            taggableType="Workspace"
            labels={organizationLabels}
            cacheTags={[{ type: 'Opp', id: workspace.workableId }]}
          />
          {drawerType && (
            <DescriptionListField
              label="Actions"
              value={
                <Tooltip content="Click to view workspace in new tab">
                  <LinkTag to={`/opportunities/${workspace.workableId}/workspaces/${workspace.id}`} target="_blank">
                    <Tag interactive minimal>
                      Open in new tab
                    </Tag>
                  </LinkTag>
                </Tooltip>
              }
              cols={2}
            />
          )}
          <DescriptionListField label="Created At" value={formatTime(createdAt, DATETIME_24_SHORT)} cols={2} />
          <DescriptionListField label="Created By" value={createdByEmail} cols={2} />
          <DescriptionListField
            label="Workspace Access"
            value={
              <Tooltip content={privateAccess ? 'Invited team members may join' : 'All team members may join'}>
                <Tag
                  minimal
                  intent={privateAccess ? 'danger' : 'success'}
                  icon={<Icon iconSize={12} icon={privateAccess ? 'lock' : 'unlock'} />}
                >
                  {privateAccess ? 'Private' : 'Open'}
                </Tag>
              </Tooltip>
            }
            cols={2}
          />
          <DescriptionListField label="Created By Organization" value={createdByOrganization} cols={1} />
          <OppDetailsOrganizationOppContext id={workableId} />
          <div className="col-span-2 flex flex-col gap-y-1">
            <DescriptionListField
              label="Participating Organizations"
              value={
                <div className="space-x-2">
                  {participatingOrganizations.map(organizationName => {
                    if (participatingOrganizations.length > 1 && organizationName === createdByOrganization) {
                      return (
                        <Tag key={organizationName} minimal intent="warning">
                          {`Created by ${organizationName}`}
                        </Tag>
                      );
                    }

                    return (
                      <Tag key={organizationName} minimal>
                        {organizationName}
                      </Tag>
                    );
                  })}
                </div>
              }
              cols={1}
            />
          </div>
        </dl>
      </CardListItem>

      {workspaceImprovementsEnabled && (
        <CardListItem title="Attachments" collapsible defaultIsOpen titleRenderer={CardHeadingSmall}>
          <OppWorkspaceAttachmentsCard workspace={workspace} />
        </CardListItem>
      )}
    </CardList>
  );
};

OppWorkspaceCard.propTypes = propTypes;
