import { SearchMeta, WithSearchableActions } from 'app/hooks/search/types';
import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import { StoreApi, UseBoundStore } from 'zustand';
import { useEffectOnce } from 'app/hooks/useEffectOnce';

export const SET_STATE_TIMEOUT = 500;

export const useFormikSavableSearch = <
  SavedSearch extends { id: string },
  Values extends { meta: SearchMeta },
  Store extends UseBoundStore<StoreApi<WithSearchableActions<Values>>>
>({
  getStateFromSavedSearch,
  savedSearches,
  store
}: {
  savedSearches: SavedSearch[];
  getStateFromSavedSearch?: (args: { savedSearch: SavedSearch; meta: SearchMeta }) => Values;
  store: Store;
}) => {
  useCachedFormValues<Values, Store>({ store });
  useHydrateSavedSearch({ getStateFromSavedSearch, savedSearches });
};

const useCachedFormValues = <
  Values extends { meta: SearchMeta },
  Store extends UseBoundStore<StoreApi<WithSearchableActions<Values>>>
>({
  store
}: {
  store: Store;
}) => {
  const { values } = useFormikContext<Values>();
  const valuesRef = useRef(values);

  useEffect(() => {
    valuesRef.current = values;

    const timeout = setTimeout(() => {
      const cachedValues = valuesRef.current;
      store.setState(current => ({ ...current, ...cachedValues }), true);
    }, SET_STATE_TIMEOUT);

    return () => {
      clearTimeout(timeout);
    };
  }, [store, values]);
};

const useHydrateSavedSearch = <SavedSearch extends { id: string }, Values extends { meta: SearchMeta }>({
  savedSearches,
  getStateFromSavedSearch
}: {
  savedSearches: SavedSearch[];
  getStateFromSavedSearch?: (args: { savedSearch: SavedSearch; meta: SearchMeta }) => Values;
}) => {
  const { values, setValues, submitForm } = useFormikContext<Values>();
  const { savedSearchId, searchState } = values.meta;

  useEffectOnce(() => {
    if (searchState === 'initializing') {
      const savedSearch = savedSearchId ? savedSearches.find(({ id }) => id === savedSearchId) : undefined;

      if (savedSearch && getStateFromSavedSearch) {
        const savedSearchState = getStateFromSavedSearch({
          savedSearch,
          meta: { ...values.meta, searchState: 'ready' }
        });
        setValues(savedSearchState);
      } else {
        setValues({ ...values, meta: { ...values.meta, savedSearchId: savedSearchId, searchState: 'ready' } });
      }

      // HACK async to allow values to update
      setTimeout(() => {
        submitForm();
      });
    }
  });
};
