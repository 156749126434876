import React from 'react';
import { Icon, Button, Spinner, Popover, Menu, MenuItem, Text } from '@blueprintjs/core';

import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { CardListItem } from 'app/molecules/CardList/CardList';
import { PdfIcon } from 'images/components/PdfIcon';
import { QuoteShow as Quote } from 'types/__generated__/GovlyApi';
import { match } from 'ts-pattern';

type OppWorkspaceQuoteListItemProps = {
  quote: Quote;
  setFileToView: (file?: Quote['file']) => void;
  setQuoteToView: (quote?: Quote) => void;
  handleDelete: (id: string) => void;
};

export function OppWorkspaceQuoteListItem({
  quote,
  setFileToView,
  setQuoteToView,
  handleDelete
}: OppWorkspaceQuoteListItemProps) {
  const icon = match(quote.textExtractStatus)
    .with('completed', () => <PdfIcon />)
    .with('processing', () => <Spinner size={36} intent="primary" />)
    .otherwise(() => <Icon icon="document" size={36} />);

  let subtext = 'No line items found.';

  if (quote.textExtractStatus === 'processing') {
    subtext = 'Analyzing and extracting information from your document…';
  } else if (quote.textExtractStatus === 'completed' && quote.lineItemsRaw?.length > 0) {
    subtext = `${quote.lineItemsRaw?.length} line items`;

    if (quote.lineItemsTotal) {
      const totalAsCurrency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(quote.lineItemsTotal);
      subtext += ` totalling ${totalAsCurrency}`;
    }
  }

  const disableLineItems =
    quote.textExtractStatus !== 'completed' || !quote.lineItemsRaw || quote.lineItemsRaw.length === 0;

  return (
    <CardListItem className="flex justify-between">
      <div className="flex gap-x-2">
        <div>{icon}</div>
        <div>
          <LinkTag tag="button" className="font-bold truncate max-w-64" onClick={() => setFileToView(quote.file)}>
            {quote.file?.name}
          </LinkTag>
          <Text>{subtext}</Text>
        </div>
      </div>
      <div className="flex gap-x-2">
        <div>
          <Button disabled={disableLineItems} icon="th-list" onClick={() => setQuoteToView(quote)} className="mr-2">
            Line Items
          </Button>
        </div>

        <Popover
          interactionKind="click"
          className="max-w-md"
          content={
            <Menu key="menu">
              <MenuItem icon="eye-open" text="View in Browser" onClick={() => setFileToView(quote.file)} />
              <MenuItem
                icon="import"
                text="Download"
                href={quote.file?.link}
                target="_blank"
                rel="noopener noreferrer nofollow"
              />
              <MenuItem icon="trash" text="Remove" onClick={() => handleDelete(quote.id)} />
            </Menu>
          }
        >
          <Button icon="more" />
        </Popover>
      </div>
    </CardListItem>
  );
}
