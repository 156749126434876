import React, { useState, useEffect } from 'react';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';
import { Icon, Button, Tooltip } from '@blueprintjs/core';
import { useCreateQuoteMutation, useDeleteQuoteMutation, useGetQuotesForWorkspaceQuery } from 'api/quotesApi';
import { CardList } from 'app/molecules/CardList/CardList';
import { CardError } from 'app/atoms/ErrorFallback/CardError';
import { FileViewer } from 'app/molecules/FileViewer/FileViewer';
import { FileUploader } from 'app/molecules/FileUploaders/FileUploader/FileUploader';

import { QuoteShow as Quote } from 'types/__generated__/GovlyApi';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { OppWorkspaceQuoteListItem } from './OppWorkspaceQuoteListItem';
import { OppWorkspaceQuoteDrawer } from './OppWorkspaceQuoteDrawer';

const AcceptedFileTypes = {
  ...Object.fromEntries(
    ['.pdf', '.json', '.doc', '.docx', '.xls', '.xlsx', '.xml', '.json'].map(ext => [
      `application/${ext.slice(1)}`,
      [ext]
    ])
  ),
  'image/*': ['.jpg', '.jpeg', '.png'],
  'text/*': ['.html', '.csv', '.txt', '.xml'],
  'application/vnd.ms-excel': [], // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // .xlsx
  'application/msword': [], // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [] // .docx
};

type OppWorkspaceQuoteFormProps = {
  workspaceId: string;
  quotes?: Quote[];
  workspaceName: string;
  workableDisplayName: string;
  workableId: string;
};

export function OppWorkspaceQuoteForm({
  workspaceId,
  quotes: workspaceQuotes,
  workspaceName,
  workableDisplayName,
  workableId
}: OppWorkspaceQuoteFormProps) {
  const [fileToView, setFileToView] = useState<Quote['file']>();
  const [quoteToView, setQuoteToView] = useState<Quote>();
  const [shouldPoll, setShouldPoll] = useState<boolean>(true);
  const [createQuote] = useCreateQuoteMutation();
  const [deleteQuote] = useDeleteQuoteMutation();
  const { trackEvent } = useEventTracking();

  const { data: quotes = workspaceQuotes || [] } = useGetQuotesForWorkspaceQuery(
    { workspaceId },
    { pollingInterval: 5000, skip: !shouldPoll }
  );
  // turn off polling once all quotes are completed
  useEffect(() => {
    if (quotes.length === 0) setShouldPoll(false);
    if (!quotes.some(quote => quote.textExtractStatus === 'processing')) setShouldPoll(false);
  }, [quotes]);

  const onAttach = async (attachment: { signedId: string }) => {
    const res = await createQuote({ workspaceId, file: attachment.signedId });
    if ('error' in res) {
      throw new Error("We're sorry, something went wrong. Please try again later.");
    }
    setShouldPoll(true);
  };

  const onClick = () => trackEvent({ object: 'quote', action: 'file_uploader_clicked', properties: { workspaceId } });

  const handleDelete = async (id: string) => {
    const res = await deleteQuote({ id });
    if ('error' in res) {
      throw new Error("We're sorry, something went wrong. Please try again later.");
    }
    setShouldPoll(true);
  };

  return (
    <ErrorBoundary action="OppWorkspaceQuoteForm" fallback={<CardError title="Quotes" />}>
      <CardList
        title="Quotes"
        rightElement={
          <FileUploader
            id={'opp-workspace-quote-zone-dropzone'}
            wrapperElement="div"
            useUploadArgs={{
              maxFiles: 1,
              accept: AcceptedFileTypes
            }}
            inputProps={{
              id: `opp-workspace-quote-form-dropzone-input`
            }}
            onAttach={onAttach}
          >
            <Tooltip content={`Upload a new quote`}>
              <Button icon="plus" onClick={onClick} />
            </Tooltip>
          </FileUploader>
        }
      >
        {quotes.length === 0 ? (
          <FileUploader
            id={'opp-workspace-quote-zone-dropzone'}
            wrapperElement="div"
            className="relative px-4 py-5 lg:p-6"
            useUploadArgs={{
              maxFiles: 1,
              accept: AcceptedFileTypes
            }}
            inputProps={{
              className: 'absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md',
              id: `opp-workspace-quote-form-dropzone-input`
            }}
            onAttach={onAttach}
          >
            <div
              className="group border-2 border-gray-200 border-dashed rounded-2xl p-4 flex flex-col items-center hover:cursor-pointer hover:bg-gray-50"
              onClick={onClick}
            >
              <Icon icon="cloud-upload" className="text-gray-500" size={20} />
              <span className="text-gray-500">
                <span className="group-hover:underline">
                  Choose a PDF or drop one here to upload into this workspace.
                </span>
              </span>
            </div>
          </FileUploader>
        ) : (
          quotes.map(quote => (
            <OppWorkspaceQuoteListItem
              key={quote.id}
              quote={quote}
              setFileToView={setFileToView}
              setQuoteToView={setQuoteToView}
              handleDelete={handleDelete}
            />
          ))
        )}
      </CardList>

      <OppWorkspaceQuoteDrawer
        quote={quoteToView ?? undefined}
        setQuoteToView={setQuoteToView}
        workspaceName={workspaceName}
        workableDisplayName={workableDisplayName}
        workableId={workableId}
      />

      {!!fileToView?.link && (
        <FileViewer
          downloadLink={fileToView?.link}
          fileName={fileToView?.name}
          fileUrl={fileToView?.link}
          onClose={() => setFileToView(undefined)}
        />
      )}
    </ErrorBoundary>
  );
}
