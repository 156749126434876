import React, { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { AnchorButton, FormGroup, Callout, Menu, MenuItem, Tag, InputGroup, Popover, Classes } from '@blueprintjs/core';
import { cn } from 'app/lib/cn';
import truncate from 'lodash-es/truncate';
import keyBy from 'lodash-es/keyBy';

import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { AvatarList } from 'app/molecules/AvatarList/AvatarList';
import { useFuzzySearch } from 'app/hooks/useFuzzySearch';
import { useGetOppSearchesQuery } from 'api/oppSearchesApi';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';
import { Tabs } from 'app/molecules/Tabs/Tabs';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { getStateFromSavedSearch } from 'app/hooks/search/useOppSearchCache';
import { useSearchParams } from 'react-router-dom';
import { defaultState } from 'app/hooks/search/useOppSearchCache';

export const OppSearchSavedSearchSelectInput = () => {
  const { values, setValues, submitForm } = useFormikContext();
  const { isMobile } = useDeviceWidth();
  const currentUserId = useCurrentUserAttribute('id');
  const selected = values.meta.savedSearchId;
  const [query, setQuery] = useState();
  const [savedSearchType, setSavedSearchType] = useState('following');
  const [searchParams, setSearchParams] = useSearchParams();

  const clearSavedSearch = () => {
    setValues(defaultState);
    searchParams.delete('savedSearchId');
    setSearchParams(searchParams);
    submitForm();
  };

  const suggestedSearches = [
    {
      name: 'My Active Opportunities',
      key: 'myActiveOpportunities',
      query: {
        status: ['open'],
        following: [currentUserId]
      }
    }
  ];

  const { data = [], isLoading } = useGetOppSearchesQuery({ active: true });

  let items = data;

  if (savedSearchType === 'following') {
    items = items.filter(item => item.currentUserFollowing);
  }

  if (savedSearchType === 'suggested') {
    items = suggestedSearches;
  }

  const results = useFuzzySearch({
    options: { keys: ['name', 'follows.organizationUser.email', 'follows.organizationUser.name'] },
    data: items,
    query
  });

  const itemValueMap = useMemo(() => keyBy(data, 'id'), [data]);
  const selectedSearch = itemValueMap[selected];

  const tabs = [
    {
      text: 'My Searches',
      tag: 'a',
      onClick: e => {
        e.preventDefault();
        setSavedSearchType('following');
      },
      current: savedSearchType === 'following' ? 'following' : null
    },
    {
      text: 'Team Searches',
      tag: 'a',
      onClick: e => {
        e.preventDefault();
        setSavedSearchType('team');
      },
      current: savedSearchType === 'team' ? 'team' : null
    }
  ];

  if (!isMobile) {
    tabs.push({
      text: 'Suggested',
      tag: 'a',
      onClick: e => {
        e.preventDefault();
        setSavedSearchType('suggested');
      },
      current: savedSearchType === 'suggested' ? 'suggested' : null
    });
  }

  const content = (
    <Menu
      large
      className={cn('max-h-96 max-w-md space-y-4 overflow-y-auto px-4', {
        'py-4': results.length === 0,
        'pt-4 pb-2': results.length > 0
      })}
    >
      <Tabs tabs={tabs} navClassName="justify-between" data-test="saved-search-tabs" />
      {items.length === 0 && savedSearchType === 'team' && (
        <Callout className="mt-4" intent="primary">
          Your team has not shared any saved searches.
        </Callout>
      )}
      {items.length === 0 && savedSearchType === 'following' && (
        <Callout className="mt-4" intent="danger">
          You are not following any saved searches.
        </Callout>
      )}
      {items.length > 3 && (
        <InputGroup
          autoFocus
          name="searchFilter"
          data-test="search-filter-input"
          placeholder="Filter saved searches"
          onChange={e => setQuery(e.target.value)}
        />
      )}

      <div className="divide-y">
        {results.map(savedSearch => (
          <MenuItem
            key={savedSearch.id || savedSearch.key}
            data-test="saved-search-item"
            onClick={() => {
              const savedSearchState = getStateFromSavedSearch({
                savedSearch,
                meta: { ...values.meta, savedSearchId: savedSearch.id, savedSearchUpdatedAt: Date.now() }
              });

              setValues(savedSearchState);
              searchParams.set('savedSearchId', savedSearch.id);
              setSearchParams(searchParams);

              submitForm();
            }}
            text={
              <div className="flex items-center justify-between">
                <span className="flex-1">{truncate(savedSearch.name, { length: isMobile ? 20 : 36 })}</span>
                <AvatarList
                  limit={2}
                  avatarProps={{
                    size: 'sm'
                  }}
                  avatarData={savedSearch.follows?.map(
                    ({ organizationUser: { name, initials, organizationName, avatar, avatarColor } }) => ({
                      initials,
                      name,
                      organizationName,
                      avatar,
                      avatarColor
                    })
                  )}
                />
              </div>
            }
            active={savedSearch.id && selected === savedSearch.id}
            className={Classes.POPOVER_DISMISS}
          />
        ))}
      </div>
    </Menu>
  );

  return (
    <FormGroup
      contentClassName="mt-2"
      className="m-0 pb-0 pt-6 sm:pb-0 py-6"
      label={<LabelSpan label="Saved Searches" />}
      helperText={
        selectedSearch?.currentUserFollowing ? (
          <Tag className="mb-6" intent="success">
            Following
          </Tag>
        ) : null
      }
    >
      <div className="flex items-center space-x-2 w-full">
        <Popover interactionKind="click" placement="bottom" fill content={content} className="max-w-md">
          <AnchorButton
            rightIcon="chevron-down"
            data-test="saved-search-dropdown"
            text={truncate(itemValueMap[selected]?.name, { length: 30 }) || 'Select'}
            className="inline-flex w-full justify-between truncate sm:w-72"
            fill
            large
            disabled={isLoading}
          />
        </Popover>
        {selectedSearch && (
          <AnchorButton onClick={clearSavedSearch} icon="cross" intent="danger" minimal data-test="clear-saved-search">
            Clear
          </AnchorButton>
        )}
      </div>
    </FormGroup>
  );
};
