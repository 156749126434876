import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Icon, Tooltip } from '@blueprintjs/core';

import { WorkflowStageTag } from 'app/molecules/WorkflowStageTag/WorkflowStageTag';
import { AvatarList } from 'app/molecules/AvatarList/AvatarList';
import { OppWorkspaceHeader } from 'app/organisms/OppWorkspaceHeader/OppWorkspaceHeader';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { showWorkspace } from 'app/lib/authorize';
import { LabelList } from 'app/organisms/LabelList/LabelList';

export const OppWorkspacePreview = ({ workspace, displayableFollows, headerClass, followsClass, onClick }) => {
  const {
    id,
    name,
    workflowStage,
    follows = [],
    privateAccess,
    commentsCount,
    archivedAt,
    createdById,
    organizationDefault,
    createdByOrganization,
    organizationLabels = [],
    workableId: oppId,
    bid
  } = workspace;

  const followsList = displayableFollows || follows;

  const {
    data: { id: currentOrgUserId, role: currentOrgUserRole }
  } = useGetCurrentUserQuery();

  const participatingOrganizations = [
    ...new Set(followsList.map(({ organizationUser: { organizationName } }) => organizationName))
  ].sort(n => (n === createdByOrganization ? -1 : 1));

  const canView = showWorkspace({ privateAccess, follows, currentOrgUserRole, createdById, currentOrgUserId });

  return (
    <>
      <div className={headerClass}>
        <OppWorkspaceHeader
          id={id}
          onClick={onClick}
          oppId={oppId}
          name={name}
          organizationDefault={organizationDefault}
          archivedAt={archivedAt}
          showLink={canView}
          createdByOrganization={createdByOrganization}
          bid={bid}
        />
        <div className="flex flex-wrap items-center gap-2">
          <WorkflowStageTag workflowStage={workflowStage} />
          {!!commentsCount && (
            <Tag
              minimal
              icon={<Icon iconSize={12} icon="chat" />}
              intent="primary"
              interactive={canView}
              onClick={canView ? onClick : null}
            >
              {commentsCount}
            </Tag>
          )}
          {privateAccess && (
            <Tooltip content="Only invited team members may join">
              <Tag minimal intent="danger" icon={<Icon iconSize={12} icon="lock" />} />
            </Tooltip>
          )}
        </div>

        <div className="w-full">
          <LabelList labels={organizationLabels} />
        </div>
      </div>

      {!!followsList.length && (
        <div className={followsClass}>
          <AvatarList
            avatarData={followsList.map(
              ({ organizationUser: { name, initials, organizationName, avatar, avatarColor }, active }) => ({
                initials,
                name,
                organizationName,
                avatar,
                avatarColor,
                notificationProps: {
                  intent: active ? 'success' : 'danger',
                  icon: active ? 'thumbs-up' : 'thumbs-down',
                  size: 'xl',
                  position: 'bottom'
                }
              })
            )}
          />
          <div className="flex flex-wrap gap-1">
            {participatingOrganizations.map(organizationName => {
              if (participatingOrganizations.length > 1 && organizationName === createdByOrganization) {
                return (
                  <Tag key={organizationName} minimal intent="warning">
                    {`Created by ${organizationName}`}
                  </Tag>
                );
              }

              return (
                <Tag key={organizationName} minimal>
                  {organizationName}
                </Tag>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

OppWorkspacePreview.propTypes = {
  workspace: PropTypes.object.isRequired,
  displayableFollows: PropTypes.array,
  contentClass: PropTypes.string,
  headerClass: PropTypes.string,
  followsClass: PropTypes.string,
  onClick: PropTypes.func
};
